import CreativesAPI from '@/api/creative.api'

export const creativeFileTypes = {
  Image: 'Image',
  Video: 'Video',
  Audio: 'Audio',
  Pdf: 'Pdf',
  Binary: 'Binary'
}

export const MEDICARE_FLAG = 'MEDICARE'

const state = {  
  creativeFiles: [],
  creativeApprovalDocs: [],
  creatives: [],
  selectedCreative: {},
  creativeReviews: [],
  selectedCreativeReview: {}

}

const getters = {   
  getSelectedCreativeUrls(state) {
    return state.selectedCreative?.files?.map(f => f) ?? []
  },
  getSelectedCreativeReviewUrls(state) {
    return state.selectedCreativeReview?.creative?.files?.map(f => f) ?? []
  }  
}

const actions = {
  async uploadCreativeFile({ commit, rootGetters }, {request, progressCallback, isApprovalDoc}) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.uploadSimple({
      accountId: accountId, 
      request: request, 
      progressCallback: progressCallback
    })
    if (isApprovalDoc) {
      commit('ADD_APPROVAL_DOC', response.data)
    }
    else {
      commit('ADD_CREATIVE_FILE', response.data)
    }
    return response.data
  },

  async createCreative({ rootGetters }, request) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.create({
      accountId: accountId, 
      request: request
    })
    return response
  },
  
  async getCreatives({ commit, rootGetters }) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getSellerCreatives({
      accountId: accountId
    })
    commit('SET_CREATIVES', response?.data || [])
    return response
  },  

  async getCreative({ commit, rootGetters }, creativeId) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getSellerCreative({
      accountId: accountId,
      creativeId: creativeId
    })
    commit('SET_SELECTED_CREATIVE', response?.data || [])
    return response
  },

  async getCreativeReview({ commit, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getCreativeReview({
      accountId: accountId,
      id: id
    })
    commit('SET_SELECTED_CREATIVE_REVIEW', response?.data || [])
    return response
  }, 
  
  async getCreativeReviews({ commit, rootGetters }) {
    const accountId = rootGetters['auth/accountDetails']?.id    
    const response = await CreativesAPI.getCreativeReviews({
      accountId: accountId
    })
    commit('SET_CREATIVE_REVIEWS', response?.data || [])
    return response
  }, 

  async approveCreativeReview({ dispatch, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.reviewBuyerCreative({
      accountId: accountId,
      request: {
        creativeReviewId: id, 
        isApproved: true
      }
    })
    
    await dispatch('getCreativeReviews')
    
    return response
  },

  async rejectCreativeReview({ dispatch, rootGetters }, {id, rejectionReason}) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.reviewBuyerCreative({
      accountId: accountId,
      request: {
        creativeReviewId: id, 
        isApproved: false,
        rejectionReason: rejectionReason
      }
    })
    
    await dispatch('getCreativeReviews')
   
    return response
  }, 

  async enableCreative({ dispatch, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.enable({
      accountId: accountId,
      creativeId: id
    })
    
    await dispatch('getCreatives')

    return response
  },

  async disableCreative({ dispatch, rootGetters }, id) {
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CreativesAPI.disable({
      accountId: accountId,
      creativeId: id
    })

    await dispatch('getCreatives')
    
    return response
  },
}

const mutations = {
  ADD_CREATIVE_FILE(state, data) {
    state.creativeFiles.push(data)
  },
  RESET_CREATIVE_FILES(state) {
    state.creativeFiles = []
  },
  REMOVE_CREATIVE_FILE(state, fileId) {
    const index = state.creativeFiles.indexOf(fileId)
    state.creativeFiles.splice(index, 1)
  },
  ADD_APPROVAL_DOC(state, data) {
    state.creativeApprovalDocs.push(data)
  },
  RESET_APPROVAL_DOCS(state) {
    state.creativeApprovalDocs = []
  },
  REMOVE_APPROVAL_DOC(state, fileId) {
    const index = state.creativeApprovalDocs.indexOf(fileId)
    state.creativeApprovalDocs.splice(index, 1)
  },
  SET_CREATIVES(state, data) {
    state.creatives = data    
  },
  SET_SELECTED_CREATIVE(state, data) {
    state.selectedCreative = data    
  },
  SET_SELECTED_CREATIVE_REVIEW(state, data) {
    state.selectedCreativeReview = data    
  },
  SET_CREATIVE_REVIEWS(state, data) {
    state.creativeReviews = data    
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}