<template>
  <div class="d-flex flex-column mr-3">
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="getSelectedCreativeReviewUrls"
      :index="lightboxIndex"
      zoom-disabled
      rotate-disabled
      move-disabled
      @hide="lightboxVisible = false"
      @on-index-change="onImageIndexChange"
    >
      <template
        v-if="selectedCreativeReview && selectedCreativeReview.status === 'Pending'"
        v-slot:toolbar
      >
        <h5 class="pt-5 text-center">
          Showing {{ currentLightboxIndex + 1 }} of {{ getSelectedCreativeReviewUrls.length }} files
        </h5>

        <div class="vel-custom-btns-wrapper">
          <b-button
            variant="success"
            size="md"
            class="mr-3"
            @click="showConfirmApproveCreative"
          >         
            Approve Creative
          </b-button>
          <b-button 
            variant="danger"
            size="md"
            @click="showConfirmRejectCreative"
          >
            Reject Creative
          </b-button>  
        </div>     
      </template>
    </vue-easy-lightbox>    
  
    <Panel
      size="small"
      loader-text="Loading Creatives"
      :loading="loadingCreatives"      
    >
      <template v-slot:header>
        <span data-cy="heading">Review Creatives</span>
      </template>
      <template v-slot:content>
        <b-table
          :fields="fields"          
          :items="creativeReviews"
          responsive
          thead-class="text-nowrap"
          tbody-tr-class="text-nowrap"
          primary-key="id"
        >
          <template #cell(status)="row">
            <b-badge
              tag="div"
              class="w-100 py-1"
              :variant="getStatusMap(row.item.status).variant"
            >
              {{ getStatusMap(row.item.status).text }}
            </b-badge>            
          </template>
  
          <template #cell(name)="row">
            {{ row.item.creative.name }}
          </template>

          <template #cell(cmsId)="row">
            {{ row.item.creative.cmsId }}
          </template>
  
          <template #cell(landingPageUrl)="row">
            {{ row.item.creative.landingPageUrl }}
          </template>
  
          <template #cell(categoryName)="row">
            {{ row.item.creative.categoryName }}
          </template>
  
          <template #cell(trafficSourceName)="row">
            {{ row.item.creative.trafficSourceName }}
          </template>
  
          <template #cell(country)="row">
            {{ row.item.creative.countryCode }}
          </template>
  
          <template #cell(language)="row">
            {{ row.item.creative.languageCode }}
          </template>
  
          <template #cell(actions)="row">
            <ButtonLoader
              v-if="row.item && row.item.status === 'Pending'"
              size="sm"
              variant="warning"            
              title="Review Media Files"
              :custom-button="false"
              :loading="Boolean(loadingFiles[row.item.id])"
              show-text-with-loader
              @click="loadCreativeReview(row.item.id)"
            >
              Review
            </ButtonLoader>
            <ButtonLoader
              v-if="row.item && row.item.status !== 'Pending' && row.item.creative.files && row.item.creative.files.length > 0"
              size="sm"        
              variant="primary"
              title="Show Media Files"
              :custom-button="false"
              :loading="Boolean(loadingFiles[row.item.id])"
              show-text-with-loader
              @click="loadCreativeReview(row.item.id)"
            >
              View
            </ButtonLoader>
          </template>
        </b-table>
      </template>
    </Panel>

    <b-modal
      id="confirm-approve-modal"
      ref="confirm-approve-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Creative Approval"
      ok-title="Approve"
      button-size="sm"
      @ok="approveCreative"
    >
      <p
        v-if="selectedCreativeReview && selectedCreativeReview.creative"
        class="mb-2"
      >
        Are you sure you want to approve <strong>{{ selectedCreativeReview.creative.name }}</strong>?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('confirm-approve-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="approveCreative"
        >
          Approve
        </BaseButtonLoader>
      </template>
    </b-modal>

    <b-modal
      id="confirm-reject-modal"
      ref="confirm-reject-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Creative Rejection"
      ok-title="Reject"
      button-size="sm"
      @ok="rejectCreative"
    >
      <p
        v-if="selectedCreativeReview && selectedCreativeReview.creative"
        class="mb-2"
      >
        Are you sure you want to reject <strong>{{ selectedCreativeReview.creative.name }}</strong>?
      </p>

      <BaseFormTextArea
        v-model="selectedCreativeReview.rejectionReson"
        :horizontal="true"
        :small="true"
        internal-platform-input
        label="Rejection reason"
        width-lg="5"
        width-xl="3"
        type="text"       
        rows="3"
        plaintext
        required
        input-class="px-0"   
      />

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('confirm-reject-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="danger"
          @click="rejectCreative"
        >
          Reject
        </BaseButtonLoader>
      </template>
    </b-modal>
  </div>
</template>
        
<script>
  
import { showErrorMessage } from '@/notification-utils'
import formInputMixin from '@/mixins/formInputMixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { mapActions, mapState, mapGetters } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
        
export default {
  name: 'ReviewCreatives',
  
  components: {
    VueEasyLightbox,  
  },
        
  title: 'Review Creatives', 
      
  mixins: [formInputMixin, copyToClipboardMixin],
        
  data() {
    return {
      loadingCreatives: false, 
      lightboxVisible: false,
      lightboxIndex: 0,
      currentLightboxIndex: 0,
      loadingFiles: {},
      isLoading: false,      
      fields: [            
        { key: 'status', label: 'Status', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'cmsId', label: 'CMS ID', sortable: true },      
        { key: 'landingPageUrl', label: 'Landing Page URL', sortable: true },
        { key: 'categoryName', label: 'Category', sortable: true },
        { key: 'trafficSourceName', label: 'Traffic Source', sortable: true },       
        { key: 'country', label: 'Country', sortable: true },
        { key: 'language', label: 'Language', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    }
  },
  
  computed: {
    ...mapState('creative', [ 'creativeReviews','selectedCreativeReview' ]),
    ...mapGetters('creative', ['getSelectedCreativeReviewUrls'])    
  },
    
  async created() {
    try {   
      this.isLoading = true   
      await this.getCreativeReviews()
    } catch (error) {
      showErrorMessage(error.response?.data?.title)
    }
    finally {
      this.isLoading = false
    }
  },
  
  methods: {
    ...mapActions('creative', [ 'getCreativeReviews','getCreativeReview', 'approveCreativeReview', 'rejectCreativeReview' ]),
  
    getStatusMap(status) {
      const statusToVariantMap = {
        Pending: {variant:'secondary', text:'Review Pending'},
        Rejected: {variant:'danger', text:'Rejected'},
        Approved: {variant:'success', text:'Approved'},
        Disabled: {variant:'warning', text:'Disabled'}        
      }
      return statusToVariantMap[status] ?? {variant:'dark', text: 'None'}
    },

    showConfirmApproveCreative() {      
      this.$bvModal.show('confirm-approve-modal')
      this.lightboxVisible = false
    },

    showConfirmRejectCreative() {      
      this.$bvModal.show('confirm-reject-modal')
      this.lightboxVisible = false
    },
  
    async loadCreativeReview(id){
      try {
        this.currentLightboxIndex = 0
        this.$set(this.loadingFiles, id, true)
        await this.getCreativeReview(id)
        this.lightboxVisible = true
      } catch (error) {
        showErrorMessage("Failed to open files")
      } finally {
        this.$set(this.loadingFiles, id, false)
      }
    },

    async approveCreative(){
      try {
        this.loadingCreatives = true
        await this.approveCreativeReview(this.selectedCreativeReview.id)  
      } catch (error) {
        showErrorMessage("Failed to approve creative")
      } finally {
        this.loadingCreatives = false
        this.$bvModal.hide('confirm-approve-modal')
      }
    },

    async rejectCreative(){
      try {
        this.loadingCreatives = true
        await this.rejectCreativeReview({
          id: this.selectedCreativeReview.id, 
          rejectionReason: this.selectedCreativeReview.rejectionReason
        }) 
      } catch (error) {
        showErrorMessage("Failed to reject creative")
      } finally {
        this.loadingCreatives = false
        this.$bvModal.hide('confirm-reject-modal')
      }
    },

    onImageIndexChange(oldIndex, newIndex) {
      this.currentLightboxIndex = newIndex
    }
  }
}
</script>
  <style lang="scss" scoped>
  </style>